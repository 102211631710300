import { Tag } from "antd";
import { LoanContractstatus } from "../../utils/types";

interface LoanContractStatusTagProps {
    status: string;
}


export const LoanContractStatusTag = ({ status }: LoanContractStatusTagProps) => {

const loanContractsStatusMap = {
    [LoanContractstatus.ACTIVE]: {
        text: "ACTIVE",
        style: "bg-alert-success",
    },
    [LoanContractstatus.DEFAULT]: {
        text: "IN DEFAULT",
        style: "bg-alert-danger",
    },
    [LoanContractstatus.RECOVERY]: {
        text: "IN RECOVERY",
        style: "bg-alert-danger",
    },
    [LoanContractstatus.RESTRICTED]: {
        text: "IS RESTRICTED",
        style: "bg-alert-danger",
    },
    [LoanContractstatus.CLOSED]: {
        text: "CLOSED",
        style: "bg-alert-neutral",
    },
}

    return (
        <Tag
            className={
                `${loanContractsStatusMap[status as LoanContractstatus]?.style || "bg-alert-warning"} text-white border-none`
            }
        >
            {loanContractsStatusMap[status as LoanContractstatus]?.text || status}
        </Tag>
    )
}
