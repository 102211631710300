export enum Feature {
    LOAN_PROGRAM_MANAGEMENT = 'LOAN_PROGRAM_MANAGEMENT',
    LOCALGOVERNMENT_ACTIVATION = 'LOCALGOVERNMENT_ACTIVATION',
    BACKOFFICE_WORKFLOW = 'BACKOFFICE_WORKFLOW',
    CONTRACT_INTEREST_WAIVE = 'CONTRACT_INTEREST_WAIVE',
    SKIP_FIELD_VERIFICATION = 'SKIP_FIELD_VERIFICATION',
    SILENT_VERIFICATION = 'silent-verification',
    GUARANTOR_INFORMATION = 'GUARANTOR_INFORMATION',
    TERMINATE_LOAN_CONTRACT = 'TERMINATE_LOAN_CONTRACT',
}
