import React from "react";
import {get_pending_overdraft_contract_termination_request} from "../API";
import {showServerError} from "../../../../utils/notifications";

interface Params {
    ref: string;
}

const useQueryPendingOverdraftTerminationRequest = ({ ref }: Params) => {
    const [isLoadingOverdraftTerminationRequest, setIsLoadingOverdraftRequest] =
        React.useState(false);
    const [hasPendingOverdraftTerminationRequest, setHasPendingOverdraftTerminationRequest] = React.useState(false);

    const checkPendingOverdraftTerminationRequest = async () => {
        try {
            setIsLoadingOverdraftRequest(true);
            const response = await get_pending_overdraft_contract_termination_request(ref);
            setHasPendingOverdraftTerminationRequest(response.hasPendingRequest);
        } catch (error: any) {
            showServerError(error);
        } finally {
            setIsLoadingOverdraftRequest(false);
        }
    };

    React.useEffect(() => {
        checkPendingOverdraftTerminationRequest();
    }, [ref]);

    return {
        isLoadingOverdraftTerminationRequest,
        hasPendingOverdraftTerminationRequest,
        checkPendingOverdraftTerminationRequest
    };
};

export default useQueryPendingOverdraftTerminationRequest;
