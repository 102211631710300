export enum LoanContractstatus {
  "ACTIVE" = "ACTIVE",
  "DEFAULT" = "DEFAULT",
  "RECOVERY" = "RECOVERY",
  "RESTRICTED" = "RESTRICTED",
  "CLOSED" = "CLOSED",
}

export enum LoanContractManagerType {
  FIRST_FCO = "FIRST_FCO",
  SECOND_FCO = "SECOND_FCO",
  PM = "PM",
}

export type LoanContractDetails = {
  loanContractReference: string;
  loanRequestReference: string;
  businessOwnerName: string;
  businessName: string;
  loanAmount: number;
  totalRepaid: number;
  outstandingBalance: number;
  loanStatus: string;
  loanTenure: number;
  loanTenureUnit: string;
  amountOverDue: number;
  daysOverDue: number;
  expectedCompletionDate: string;
  dateCompleted: string;
  interestRate: number;
  totalInterest: number;
  contractCreationDate: string;
  nextRepaymentDate: string;
  businessOwnerPhoneNumber: string;
  nextRepaymentAmount: number;
  loanProduct: string;
};

export type OverdraftContractDetails = {
  id: number;
  amount: number;
  reference: string;
  overdraftOfferId: number;
  processingFee: number;
  managementFee: number;
  tenure: number;
  loanRequestReference: string;
  loanAccountNuban: string;
  regularizationPeriod: number;
  regularizationPeriodUnit: string;
  businessOwnerCode: string;
  maxDefaultCountToTermination: number;
  defaultCount: number;
  creationDate: string;
  expectedAcceptanceDate: string;
  acceptanceDate: string;
  rejectionDate: null;
  contractStatus: string;
  runningOverdraftDetails: {
    overdraftStatus: string;
    amountOverdrawn: number;
    nextRegularizationDate: string;
    overdrawnDate: string;
  };
  primaryBusinessCodeAsOfRequest: string;
  dailyInterestRateInPercentage: number;
  interestBookingFrequency: string;
  loanProgramCode: string;
  businessLocationLgaCode: string;
  tenureUnit: string;
  hourlyInterestRateInPercentage: number;
  minimumSalesTurnover: number;
  initialApprovedLimit: number;
  currentLimit: number;
  loanContributionAmount: number;
  expectedCompletionDate: string;
};

export type LoanContractManagersDetails = {
  firstFco: LoanContractManager | null;
  secondFco: LoanContractManager | null;
  pm: LoanContractManager | null;
  rm: LoanContractManager | null;
};
export type LoanAgreement = {
  laeReference: string;
  lastUpdatedOn?: string;
  name: string;
  type: string;
};

export type LoanContractManager = {
  code: string;
  name: string;
  email: string;
  phoneNumber: string;
  photoRef: string;
};

export enum ELoanContractProduct {
  OVERDRAFT = "OVERDRAFT",
  WORKING_CAPITAL = "WCL",
}
export enum ELoanRequestProduct {
  OVD = "OVD",
  WCL = "WCL",
}
export type LoanProductType = "OVD" | "WCL";
export type LoanProduct = "OVERDRAFT" | "WCL";

export const loanProductToTypeMap: Record<LoanProduct, LoanProductType> = {
  OVERDRAFT: "OVD",
  WCL: "WCL",
};
export const loanTypeToProductMap: Record<LoanProductType, LoanProduct> = {
  OVD: "OVERDRAFT",
  WCL: "WCL",
};

export const LoanProductToDescriptionMap: Record<LoanProduct, string> = {
  OVERDRAFT: "Overdraft",
  WCL: "Working Capital Loan",
};

export enum WorkflowRequestStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
}

export interface IWCLToOVDConversionData {
  amount: number;
  dailyInterestRateInPercentage: string;
  tenure: number;
  loanTenureUnit: "DAILY" | "MONTHLY" | "YEARLY";
  managementFeeDiscountInPercentage: number;
  maxDefaultCountToTermination: number;
  regularizationPeriod: number;
  offerReference: string;
}

export interface InterestWaivePeriod {
  waivePeriodId: number;
  contractRef: string;
  startDate: string;
  endDate: string;
  status: "INACTIVE" | "ACTIVE" | "TERMINATED";
}
