import {Alert, Button, Card, Descriptions, Empty, Modal, Spin, Tooltip, Typography,} from "antd";
import React, {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import Page, {BreadCrumbItem} from "../../../../components/Page";
import ServerErrorAlert from "../../../../components/ServerErrorAlert";
import {DashboardRoutePaths} from "../../../../routes/Dashboard/routePaths";
import {
  useQueryLoanRequestDetails
} from "../../LoanRequestsManagement/LoanRequestdetails/hooks/useQueryLoanRequestDetails";
import LoanContractFCOReassignmentView from "../LoanContractReassignmentViews/FCOReassignment";
import LoanContractPMReassignmentView from "../LoanContractReassignmentViews/PMReassignment";
import useQueryLoanContractDetails from "../hooks/useQueryLoanContractDetails";
import {useQueryLoanContractManagersDetails} from "../hooks/useQueryLoanContractManagersDetails";
import useReassignLoanContractParticipant from "../hooks/useReassignLoanContractParticipant";
import {loanContractReassignmentViewTitleMap} from "../utils/dataMaps";
import {ELoanContractProduct, LoanContractManagerType, LoanContractstatus,} from "../utils/types";
import LoanAgreementsList from "./loanAgreements";
import OVDDetails from "./ovdDetails";
import WCLDetails from "./wclDetails";
import InterestWaivePeriods from "./interestWaivePeriods";
import Config from "../../../../config";
import {Feature} from "../../../../config/feature-config";
import TerminateOverdraftContract from "../../../../components/Modals/TerminateOverdraftContractModal";
import useQueryPendingOverdraftTerminationRequest from "../hooks/useQueryPendingOverdraftTerminationRequest";
import {useAppSelector} from "../../../../store";
import {
  canShowTerminateContract,
  shouldDisableTerminateContractButton
} from "../../LoanRequestsManagement/AllLoanRequests/utils";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Loan Contracts",
    path: DashboardRoutePaths.loanContracts.path,
  },
  {
    title: "View Loan Contract Details",
    active: true,
  },
];

const LoanContractDetails = () => {
  const params = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const {
    auth: { user },
  } = useAppSelector((state) => state);
  const loanProduct = searchParams.get("loan-product");
  const loanRequestRef = searchParams.get("loan-request");

  const { loading, contractDetails, overdraftContractDetails } =
    useQueryLoanContractDetails({
      ref: params?.id as string,
      loanProduct: loanProduct as ELoanContractProduct,
    });

  const { isLoadingOverdraftTerminationRequest,
          hasPendingOverdraftTerminationRequest,
          checkPendingOverdraftTerminationRequest } =
      useQueryPendingOverdraftTerminationRequest({ ref: params?.id as string });

  const { loanRequestDetails, loanRequestDetailsLoading } =
    useQueryLoanRequestDetails({
      payload: {
        id:
          loanProduct === ELoanContractProduct.OVERDRAFT
            ? (loanRequestRef as string)
            : "",
      },
    });

  const [showTerminateContractModal, setShowTerminateContractModal] = useState(false)

  const canReassign = React.useMemo(() => {
    return (
      (loanProduct === ELoanContractProduct?.WORKING_CAPITAL &&
        contractDetails?.loanStatus !== LoanContractstatus.CLOSED) ||
      (loanProduct === ELoanContractProduct?.OVERDRAFT &&
        overdraftContractDetails?.contractStatus !== LoanContractstatus.CLOSED)
    );
  }, [
    loanProduct,
    contractDetails?.loanStatus,
    overdraftContractDetails?.contractStatus,
  ]);

  const {
    error: loanContractManagersDetailsError,
    loading: loanContractManagersDetailsLoading,
    loanContractManagersDetails,
    fetchContractManagersDetails,
  } = useQueryLoanContractManagersDetails({
    contractRef: canReassign ? (params?.id as string) : "",
  });

  const { reassignmentLoading, reassignLoanContractParticipant } =
    useReassignLoanContractParticipant();

  const [reassignmentParams, setReassignmentParams] = React.useState<{
    modalView: LoanContractManagerType | null;
    managerCode: string;
  }>({ modalView: null, managerCode: "" });

  const onReassignLoanContract = ({
    officerCode,
    reason,
    callback,
  }: {
    officerCode: string;
    reason: string;
    callback(data: { requestSuccessfull: boolean }): void;
  }) => {
    const contractReference =
      loanProduct === ELoanContractProduct.WORKING_CAPITAL
        ? contractDetails?.loanContractReference
        : overdraftContractDetails?.reference;

    if (contractReference && reassignmentParams?.modalView) {
      reassignLoanContractParticipant({
        payload: {
          contractReference,
          officerCode,
          officerType: reassignmentParams?.modalView,
          reason,
        },
        callback,
      });
    }
  };

  const canShowTerminateContractButton = React.useMemo(() => {
    if (Config.excluded_features.includes(Feature.TERMINATE_LOAN_CONTRACT)) {
      return false;
    }
    const status = overdraftContractDetails?.contractStatus;
    return canShowTerminateContract({
      user,
      isLoadingPendingTerminationRequest: isLoadingOverdraftTerminationRequest,
      loanProduct,
      contractStatus: status,
    });
  }, [loanProduct, overdraftContractDetails, isLoadingOverdraftTerminationRequest]);

  const disableTerminateContractButton = React.useMemo(() => {
    return shouldDisableTerminateContractButton({
      currentLimit: overdraftContractDetails?.currentLimit,
      hasPendingTerminationRequest: hasPendingOverdraftTerminationRequest,
      isLoadingPendingTerminationRequest: isLoadingOverdraftTerminationRequest,
    });
  }, [overdraftContractDetails, isLoadingOverdraftTerminationRequest, hasPendingOverdraftTerminationRequest]);

  return (
    <>
      <Page breadCrumbItems={breadCrumbItems}>
        {loading ||
        (loanProduct === ELoanContractProduct.OVERDRAFT &&
          loanRequestDetailsLoading) ? (
          <Card>
            <section className="flex items-center justify-center h-[400px]">
              <Spin tip="Loading Details..." />
            </section>
          </Card>
        ) : (loanProduct === ELoanContractProduct?.WORKING_CAPITAL &&
            contractDetails) ||
          (loanProduct === ELoanContractProduct?.OVERDRAFT &&
            overdraftContractDetails) ? (
          <>
            <Card
              title={
                <div className="flex justify-between items-center">
                  <Typography.Title level={4}>
                    Loan Contract Details
                  </Typography.Title>
                  {canShowTerminateContractButton && (
                    <Tooltip
                      title={
                        disableTerminateContractButton
                          ? "Request is in progress"
                          : ""
                      }
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={disableTerminateContractButton}
                        onClick={() => {
                          setShowTerminateContractModal(true);
                        }}
                      >
                        Terminate Contract
                      </Button>
                    </Tooltip>
                  )}
                </div>
              }
            >
              {loanProduct === ELoanContractProduct?.WORKING_CAPITAL &&
                contractDetails && (
                  <WCLDetails contractDetails={contractDetails} />
                )}
              {loanProduct === ELoanContractProduct?.OVERDRAFT &&
                overdraftContractDetails && (
                  <OVDDetails
                    contractDetails={overdraftContractDetails}
                    loanRequestDetails={loanRequestDetails}
                  />
                )}
            </Card>

            <Card title="Contract Managers Details" className="mt-4">
              {loanContractManagersDetailsLoading ? (
                <section className="flex items-center justify-center my-8">
                  <Spin tip="Loading Details..." />
                </section>
              ) : loanContractManagersDetailsError ? (
                <section className="flex items-center justify-center my-8">
                  <ServerErrorAlert
                    message="Failed to fetch some details"
                    onRetry={() =>
                      fetchContractManagersDetails(params?.id as string)
                    }
                  />
                </section>
              ) : loanContractManagersDetails ? (
                <>
                  {loanContractManagersDetails?.firstFco && (
                    <Descriptions
                      title="First FCO"
                      layout="vertical"
                      column={{ xs: 1, md: 2, xl: 3 }}
                    >
                      <Descriptions.Item
                        label={<Typography.Text strong>Name</Typography.Text>}
                      >
                        {loanContractManagersDetails?.firstFco?.name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Typography.Text strong>Email</Typography.Text>}
                      >
                        {loanContractManagersDetails?.firstFco?.email}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>Phone Number</Typography.Text>
                        }
                      >
                        {loanContractManagersDetails?.firstFco?.phoneNumber}
                      </Descriptions.Item>
                      {canReassign && (
                        <Descriptions.Item>
                          <Button
                            type="primary"
                            className="rounded"
                            onClick={() =>
                              setReassignmentParams({
                                modalView: LoanContractManagerType.FIRST_FCO,
                                managerCode: loanContractManagersDetails
                                  ?.firstFco?.code as string,
                              })
                            }
                          >
                            Reassign First FCO
                          </Button>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  )}

                  {loanContractManagersDetails?.secondFco && (
                    <Descriptions
                      title="Second FCO"
                      layout="vertical"
                      column={{ xs: 1, md: 2, xl: 3 }}
                    >
                      <Descriptions.Item
                        label={<Typography.Text strong>Name</Typography.Text>}
                      >
                        {loanContractManagersDetails?.secondFco?.name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Typography.Text strong>Email</Typography.Text>}
                      >
                        {loanContractManagersDetails?.secondFco?.email}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>Phone Number</Typography.Text>
                        }
                      >
                        {loanContractManagersDetails?.secondFco?.phoneNumber}
                      </Descriptions.Item>
                      {canReassign && (
                        <Descriptions.Item>
                          <Button
                            type="primary"
                            className="rounded"
                            onClick={() =>
                              setReassignmentParams({
                                modalView: LoanContractManagerType.SECOND_FCO,
                                managerCode: loanContractManagersDetails
                                  ?.secondFco?.code as string,
                              })
                            }
                          >
                            Reassign Second FCO
                          </Button>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  )}

                  {loanContractManagersDetails?.pm && (
                    <Descriptions
                      title="Portfolio Manager"
                      layout="vertical"
                      column={{ xs: 1, md: 2, xl: 3 }}
                    >
                      <Descriptions.Item
                        label={<Typography.Text strong>Name</Typography.Text>}
                      >
                        {loanContractManagersDetails?.pm?.name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Typography.Text strong>Email</Typography.Text>}
                      >
                        {loanContractManagersDetails?.pm?.email}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>Phone Number</Typography.Text>
                        }
                      >
                        {loanContractManagersDetails?.pm?.phoneNumber}
                      </Descriptions.Item>
                      {canReassign && (
                        <Descriptions.Item>
                          <Button
                            type="primary"
                            className="rounded"
                            onClick={() =>
                              setReassignmentParams({
                                modalView: LoanContractManagerType.PM,
                                managerCode: loanContractManagersDetails?.pm
                                  ?.code as string,
                              })
                            }
                          >
                            Reassign PM
                          </Button>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  )}
                </>
              ) : (
                <section className="flex items-center justify-center my-8">
                  <Alert
                    message="Could not retrieve some details"
                    type="warning"
                    showIcon
                  />
                </section>
              )}
            </Card>
          </>
        ) : (
          <Card>
            <section className="flex items-center justify-center my-10">
              <Empty />
            </section>
          </Card>
        )}
        <LoanAgreementsList
          id={params?.id as string}
          loanProduct={loanProduct as ELoanContractProduct}
        />
        {Config.excluded_features.includes(
          Feature.CONTRACT_INTEREST_WAIVE,
        ) ? null : (
          <InterestWaivePeriods
            id={params?.id as string}
            contractStatus={
              loanProduct === ELoanContractProduct?.OVERDRAFT
                ? overdraftContractDetails?.contractStatus
                : contractDetails?.loanStatus
            }
          />
        )}
      </Page>

      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={
          Boolean(reassignmentParams.modalView) &&
          Boolean(reassignmentParams?.managerCode)
        }
        onCancel={() =>
          setReassignmentParams({
            modalView: null,
            managerCode: "",
          })
        }
        title={
          <Typography.Title level={4}>
            {reassignmentParams?.modalView
              ? loanContractReassignmentViewTitleMap[
                  reassignmentParams?.modalView
                ]
              : ""}
          </Typography.Title>
        }
        footer={null}
      >
        {(reassignmentParams?.modalView === LoanContractManagerType.FIRST_FCO ||
          reassignmentParams?.modalView ===
            LoanContractManagerType.SECOND_FCO) && (
          <LoanContractFCOReassignmentView
            initialOfficerCode={reassignmentParams?.managerCode}
            onSubmitLoanContractFCOReassignment={onReassignLoanContract}
            reassignmentLoading={reassignmentLoading}
          />
        )}

        {reassignmentParams?.modalView === LoanContractManagerType.PM && (
          <LoanContractPMReassignmentView
            initialOfficerCode={reassignmentParams?.managerCode}
            onSubmitLoanContractFCOReassignment={onReassignLoanContract}
            reassignmentLoading={reassignmentLoading}
          />
        )}
      </Modal>

      {showTerminateContractModal && (
        <TerminateOverdraftContract
          onClose={() => {
            setShowTerminateContractModal(false);
            checkPendingOverdraftTerminationRequest();
          }}
          contractRef={overdraftContractDetails?.reference ?? ""}
        />
      )}
    </>
  );
};

export default LoanContractDetails;
