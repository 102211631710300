import React, { useMemo, useState } from "react";
import {Button, Card, Spin, Table, Tag, Tooltip} from "antd";
import { LockOutlined } from "@ant-design/icons";
import ServerErrorAlert from "../../../../components/ServerErrorAlert";
import useQueryInterestWaivePeriods from "../hooks/useQueryInterestWaivePeriods";
import moment from "moment/moment";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import { convertStatusToHumanReadable } from "../../../../utils/dataParser";
import useQueryPendingApprovalRequest from "../hooks/useQueryPendingApprovalRequest";
import useQueryPendingTerminationRequest from "../hooks/useQueryPendingTerminationRequest";
import CreateWaiveInterestModal from "../../../../components/Modals/CreateWaiveInterestModal";
import TerminateWaivePeriodModal from "../../../../components/Modals/TerminateWaivePeriodModal";

interface InterestWaivePeriodsProps {
  contractStatus: string | undefined;
  id: string

}

const InterestWaivePeriods = ({id, contractStatus }: InterestWaivePeriodsProps) => {
  const {
    isLoadingWaivePeriods,
    waivePeriodsPermissionError,
    interestWavePeriods,
  } = useQueryInterestWaivePeriods({ ref: id });

  const { isLoadingPendingApprovalRequest, hasPendingRequest, checkPendingApprovalRequest } =
    useQueryPendingApprovalRequest({ ref: id });

  const { isLoadingPendingTerminationRequest, hasPendingTerminationRequest, checkPendingTerminationRequest } =
    useQueryPendingTerminationRequest({ ref: id });

  const [
    showWaiveTerminationConfirmationPopUp,
    setShowWaiveTerminationConfirmationPopUp,
  ] = useState(false);
  const [selectedWavePeriodId, setSelectedWavePeriodId] = useState<
    number | undefined
  >();
  const [
    showWaiveInterestConfirmationPopUp,
    setShowWaiveInterestConfirmationPopUp,
  ] = useState(false);

  const tableColumns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => {
        return (
          <Tag color={convertStatusTextToColor(value)}>
            {convertStatusToHumanReadable(value)}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "waivePeriodId",
      key: "waivePeriodId",
      render: (value: number, row: any) => {
        if (
          !isLoadingPendingTerminationRequest &&
          row?.status === "ACTIVE" &&
          canShowTerminateWaiveButton
        ) {
          return (
            <Tooltip
              title={
                hasPendingTerminationRequest
                  ? "A terminate request is in pending approval."
                  : ""
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  setSelectedWavePeriodId(value);
                  setShowWaiveTerminationConfirmationPopUp(true);
                }}
                disabled={hasPendingTerminationRequest}
              >
                Terminate
              </Button>
            </Tooltip>
          );
        }
        return <div>{"-----"}</div>;
      },
    },
  ];

  const canShowWaiveInterestButton = useMemo(() => {
    const isWaiveDataLoaded =
      !isLoadingWaivePeriods && !isLoadingPendingApprovalRequest;
    return (
      isWaiveDataLoaded &&
      contractStatus !== "CLOSED" && contractStatus !== "TERMINATED" &&
      !interestWavePeriods?.find((period) => period.status === "ACTIVE")
    );
  }, [
    isLoadingPendingApprovalRequest,
    interestWavePeriods,
    isLoadingWaivePeriods,
      contractStatus
  ]);

  const canShowTerminateWaiveButton = useMemo(() => {
    const isWaiveDataLoaded =
      !isLoadingWaivePeriods && !isLoadingPendingTerminationRequest;

    return (
      isWaiveDataLoaded &&
      contractStatus !== "CLOSED" && contractStatus !== "TERMINATED" &&
      interestWavePeriods?.find((period) => period.status === "ACTIVE")
    );
  }, [
    isLoadingPendingTerminationRequest,
    interestWavePeriods,
    isLoadingWaivePeriods,
      contractStatus
  ]);

  return (
    <>
      <Card
        title={
          <div className="flex items-center justify-between">
            <div>Interest Waive Periods</div>
            {!isLoadingPendingApprovalRequest &&
              !isLoadingWaivePeriods &&
              canShowWaiveInterestButton && (
                <Tooltip
                  title={
                    hasPendingRequest
                      ? "A waiver request is in pending approval"
                      : ""
                  }
                >
                  <Button
                    type="primary"
                    onClick={() => setShowWaiveInterestConfirmationPopUp(true)}
                    disabled={hasPendingRequest}
                  >
                    Waive Interest
                  </Button>
                </Tooltip>
              )}
          </div>
        }
        className="mt-4"
      >
        {isLoadingWaivePeriods ? (
          <section className="flex items-center justify-center my-8">
            <Spin tip="Loading Details..." />
          </section>
        ) : waivePeriodsPermissionError ? (
          <section className="flex flex-col items-center justify-center my-8">
            <LockOutlined className="text-5xl mb-5" />
            <ServerErrorAlert message="You do not have permission to view this section." />
          </section>
        ) : (
          <Table
            columns={tableColumns}
            dataSource={interestWavePeriods || []}
            pagination={false}
            rowKey={(record) => record.waivePeriodId}
            loading={isLoadingWaivePeriods}
          />
        )}
      </Card>
      {showWaiveInterestConfirmationPopUp && (
        <CreateWaiveInterestModal
          onClose={() => {
            setShowWaiveInterestConfirmationPopUp(false);
            checkPendingApprovalRequest()
          }}
          contractRef={id}
        />
      )}
      {showWaiveTerminationConfirmationPopUp && (
        <TerminateWaivePeriodModal
          onClose={() => {
            setShowWaiveTerminationConfirmationPopUp(false);
            setSelectedWavePeriodId(undefined);
            checkPendingTerminationRequest();
          }}
          contractRef={id}
          selectedWavePeriodId={selectedWavePeriodId}
        />
      )}
    </>
  );
};

export default InterestWaivePeriods;
