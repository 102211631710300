import config from "../../../../config";
import server from "../../../../server";
import {
  GuarantorEligibilityParams,
  IResponse,
  ISilentVerificationParams, TerminateContractParams,
} from "../../../../server/server.types";
import { initPagination } from "../../../../utils/pagination";
import { InterestWaivePeriod, LoanAgreement } from "../utils/types";
import { UpdateGuarantorParams } from "../hooks/useUpdateGuarantorDetails";

export const get_all_loan_contracts_service = async (
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination,
) => {
  let page = pagination.current - 1;

  const response = await server.get(
    `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts`,
    {
      params: { ...searchParams, page: page, size: pagination.pageSize },
    },
  );

  return response.data as IResponse<any>;
};

export const get_loan_contract_details = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/${ref}`,
  );

  return response.data as IResponse<any>;
};

export const get_ovd_loan_contract_details = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/overdraft/${ref}`,
  );

  return response.data as IResponse<any>;
};
export const get_loan_agreement = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/agreement/${ref}`,
  );

  return response.data as LoanAgreement[];
};
export const get_loan_agreement_file = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/agreement/${ref}/document`,
    {
      responseType: "blob",
    },
  );
  return response.data;
};

export const get_loan_contract_managers_service = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/${ref}/managers`,
  );

  return response.data as IResponse<any>;
};

export const reassign_loan_contract_participant = async (params: {
  officerType: string;
  officerCode: string;
  reason: string;
  contractReference: string;
}) => {
  const response = await server.post(
    `${config.WCL_PORTFOLIO_BASE_URL}/loan-contracts/reassign`,
    params,
  );

  return response.data;
};

export const get_interest_waive_periods = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/interest/waiver/${ref}`,
  );

  return response.data as InterestWaivePeriod[];
};

export const get_pending_approval_request = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/interest/waiver/${ref}/check-pending-request`,
  );
  return response.data as {
    hasPendingRequest: boolean;
  };
};

export const get_pending_termination_request = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/interest/waiver/terminate/${ref}/check-pending-request`,
  );
  return response.data as {
    hasPendingRequest: boolean;
  };
};

export const create_interest_waiver = async ({
  contractReference,
  startDate,
  endDate,
  comment,
}: {
  contractReference: string;
  startDate: string;
  endDate: string;
  comment: string;
}) => {
  const response = await server.post(
    `${config.WCL_LOANS_BO_BASE_URL}/interest/waiver`,
    {
      comment,
      endDate,
      startDate,
      contractReference,
    },
  );
  return response.data;
};

export const terminate_interest_waiver = async ({
  contractRef,
  waivePeriodId,
  comment,
}: {
  contractRef: string;
  waivePeriodId: string;
  comment: string;
}) => {
  const response = await server.post(
    `${config.WCL_LOANS_BO_BASE_URL}/interest/waiver/terminate`,
    {
      comment,
      waivePeriodId,
      contractRef,
    },
  );
  return response.data;
};

export const api_fetch_silent_verification_requests = async (
  pagination: Record<string, any> = initPagination,
  searchParams: Record<string, any> = {},
) => {
  let page = pagination.current - 1;
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/silent-verifications/requests`,
    {
      params: {
        ...searchParams,
        page,
        size: pagination.pageSize,
      },
    },
  );

  return response.data;
};

export const create_silent_verification = async ({
  businessName,
  businessCategory,
  businessImages,
  verificationAddress,
}: ISilentVerificationParams) => {
  const response = await server.post(
    `${config.WCL_LOANS_BO_BASE_URL}/silent-verifications`,
    {
      businessName,
      businessCategory,
      verificationAddress,
      businessImages,
    },
  );
  return response.data;
};

export const get_silent_verification_request = async (ref: string) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/silent-verifications/${ref}`,
  );
  return response.data;
};

export const update_guarantor_details = async (
  payload: UpdateGuarantorParams,
) => {
  const response = await server.put(
    `${config.WCL_LOANS_BO_BASE_URL}/loan-requests/update-guarantor`,
    payload,
  );

  return response.data;
};

export const api_fetch_guarantor_eligibility = async ({
  loanReqRef,
  bvn,
}: GuarantorEligibilityParams) => {
  const response = await server.get(
    `${config.WCL_LOANS_BO_BASE_URL}/loan-requests/${loanReqRef}/guarantors/${bvn}/update-eligibility`,
  );

  return response.data;
};


export const terminate_contract = async ({
  contractRef,
  comment,
}: TerminateContractParams) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            contractReference: "OVD|CNT|ECDE0EBD-EAF2-40D2-9240-DA28DEE7FCAD",
            comment: "reason",
            initiatedBy: "Initiator",
          }),
        2000,
      ),
    );
  } else {
    const response = await server.put(
      `${config.WCL_LOANS_BO_BASE_URL}/loan-contracts/terminate`,
      {
        contractReference: contractRef,
        comment,
      },
    );
    return response.data;
  }
};

export const get_pending_overdraft_contract_termination_request = async (
  ref: string,
) => {
  if (config.React_App_Mock) {
    return new Promise<{ hasPendingRequest: boolean }>((resolve) =>
        setTimeout(
            () =>
                resolve({
                  hasPendingRequest: true,
                }),
            2000,
        ),
    );
  } else {
    const response = await server.get<{ hasPendingRequest: boolean }>(
      `${config.WCL_LOANS_BO_BASE_URL}/loan-contracts/terminate/${ref}/check-pending-request`,
    );
    return response.data;
  }
};


/*
export const get_loan_details_service = async (loanReference: string) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanRequestDetails), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/loan-requests/${loanReference}/details`,
    );
    return response.data;
  }
};
*/
