import React from "react";
import { Form, Input, Modal, Typography } from "antd";
import { showMessage } from "../../utils/notifications";
import {
  maxCommentLength,
  minCommentLength,
} from "../../modules/Dashboard/LoanRequestsManagement/utils/constants";
import useTerminateOverdraftContract from "../../modules/Dashboard/LoanContractsManagement/hooks/useTerminateOverdraftContract";

const { Title } = Typography;
const { TextArea } = Input;

interface TerminateOverdraftContractProps {
  onClose: () => void;
  contractRef: string;
}

const TerminateOverdraftContract = ({
  onClose,
  contractRef,
}: TerminateOverdraftContractProps) => {
  const { isLoading, terminateOverdraftContract } = useTerminateOverdraftContract();

  const [form] = Form.useForm();

  const handleTerminateContract = () => {
    form
      .validateFields()
      .then(async () => {
        await terminateOverdraftContract({
          payload: {
            contractRef: contractRef,
            comment: form.getFieldValue("comments"),
          },
          callback: () => {
            showMessage("Successful", "The request has been sent for processing");
            onClose();
          },
        });
      })
      .catch(() => {});
  };

  return (
      <Modal
        destroyOnClose={true}
        centered
        open={true}
        title={<Title level={4}>Terminate Overdraft Contract</Title>}
        width={800}
        okText="TERMINATE"
        okType="primary"
        okButtonProps={{ danger: true }}
        confirmLoading={isLoading}
        onOk={handleTerminateContract}
        onCancel={() => onClose()}
      >
        <Form
          form={form}
          layout="vertical"
          name="terminate_overdraft_contract_form"
          initialValues={{ comments: "" }}
          size="large"
        >
          <Form.Item
            name="comments"
            label={
              <>
                <p>
                  Why do you want to terminate this contract?
                  <span className="text-gray-400">(Optional)</span>
                </p>
              </>
            }
            hasFeedback
            rules={[
              ({ }) => ({
                validator(_, value) {
                  if (
                    value &&
                    (value.length < minCommentLength ||
                      value.length > maxCommentLength)
                  ) {
                    return Promise.reject(
                      new Error(
                        `Comment length must be between ${minCommentLength} and ${maxCommentLength} characters.`,
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TextArea rows={4} showCount maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default TerminateOverdraftContract;
