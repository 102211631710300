import React from "react";
import {terminate_contract} from "../API";
import { showMessage, showServerError } from "../../../../utils/notifications";

interface Params {
    payload: {
        contractRef: string;
        comment: string;
    };
    callback: () => void;
}

const useTerminateOverdraftContract = () => {
    const [isLoading, setIsLoading] = React.useState(false);

    const terminateOverdraftContract = async ({ payload, callback }: Params) => {
        try {
            setIsLoading(true);

            const response = await terminate_contract(payload);

            if (response) {
                callback();
                showMessage("Successful", "Request has been sent for approval.");
            }
        } catch (error) {
            showServerError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        terminateOverdraftContract,
    };
};

export default useTerminateOverdraftContract;
