import { ILocalGovernment } from "../../../Utils/UtilsApi/utilsAPI.types";
import { useMemo } from "react";
import {
  ELoanContractProduct,
  LoanContractstatus,
} from "../../../LoanContractsManagement/utils/types";
import {
  EUserAuthorities,
  IUser,
} from "../../../../Auth/authSlice/authSlice.types";
interface CanShowTerminateContractArgs {
  user: IUser | null;
  isLoadingPendingTerminationRequest: boolean;
  loanProduct: string | null;
  contractStatus?: string;
}

interface TerminateContractButtonProps {
  hasPendingTerminationRequest: boolean;
  isLoadingPendingTerminationRequest: boolean;
  currentLimit?: number;
}

export const mapLGACodeToName = (
  code: string | undefined,
  LGAs: ILocalGovernment[],
) => {
  if (!code) {
    return "N/A";
  }
  return LGAs.find((lga) => lga.code === code)?.name || "N/A";
};

const isWorkingCapitalProduct = (product: string) =>
  product === ELoanContractProduct.WORKING_CAPITAL;

const isOverdraftProduct = (product: string) =>
  product === ELoanContractProduct.OVERDRAFT;

export const canShowRepaymentFrequency = (
  isOVDConversion: boolean,
  formState: any,
  loanDetails: { loanProduct: string },
) => {
  if (!loanDetails || isOVDConversion) return false;

  const loanProduct = !formState?.editMode
    ? loanDetails.loanProduct
    : formState?.editedData?.loanProduct;

  return isWorkingCapitalProduct(loanProduct);
};



export function canShowTerminateContract({
  user,
  isLoadingPendingTerminationRequest,
  loanProduct,
  contractStatus,
}: CanShowTerminateContractArgs): boolean {
  if (!user?.authorities) return false;
  return (
    user?.authorities?.includes(
      EUserAuthorities.LOANS_BO_CAN_TERMINATE_OVERDRAFT_CONTRACT,
    ) &&
    !isLoadingPendingTerminationRequest &&
    loanProduct === ELoanContractProduct.OVERDRAFT &&
    (contractStatus === LoanContractstatus.ACTIVE ||
      contractStatus === LoanContractstatus.DEFAULT ||
      contractStatus === LoanContractstatus.RECOVERY ||
      contractStatus === LoanContractstatus.RESTRICTED)
  );
}

export function shouldDisableTerminateContractButton({
  hasPendingTerminationRequest,
  isLoadingPendingTerminationRequest,
  currentLimit,
}: TerminateContractButtonProps): boolean {
  return (
    !isLoadingPendingTerminationRequest &&
    (currentLimit === 0 || hasPendingTerminationRequest)
  );
}
